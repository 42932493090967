import axiosClient from "./client_axios";
import axiosClient2 from "./clientAxios2";
import {
  GET_DASHBOARD_REPORT,
  NFT_LIST_ITEMS,
  GET_DOWNLOAD_IMAGE,
  GET_URL_EXPORT_TRANSACTION,
} from "./endpoint";

export const TSService = {
  getListTransaction: (params) => {
    return axiosClient.get(NFT_LIST_ITEMS, { params });
  },

  getDashboardReport: (params) => {
    return axiosClient.get(GET_DASHBOARD_REPORT, { params });
  },

  getDownloadImage: (params) => {
    return axiosClient2.get(GET_DOWNLOAD_IMAGE, { params });
  },

  getExportURLTransaction: (params) => {
    return axiosClient.get(GET_URL_EXPORT_TRANSACTION,{ params , responseType: 'blob'});
  },
  
};
