import { combineReducers } from "redux";
import config from "./config";
import leftSidebar from "./left-sidebar";
import palettes from "./palettes";
import transaction from "./transaction.reducer";
import password from "./password.reducer";
import dashboard from "./dashboard.reducer";
import navigation from "./navigation";
import UrlPayment from "./urlpayment.reducer";
import { auth } from "./auth.reducer";
import checkout from "./checkout.reducer";

const rootReducer = combineReducers({
  navigation,
  config,
  leftSidebar,
  palettes,
  auth,
  transaction,
  dashboard,
  password,
  UrlPayment,
  checkout,
});

export default rootReducer;
