/**
 * * Common
 */
export const ROUTE = "portal";

/**
 * * Transaction
 */
export const NFT_ENDPOINT = "transactions";
export const NFT_LIST_ITEMS = `${ROUTE}/${NFT_ENDPOINT}`;
export const GET_URL_EXPORT_TRANSACTION = `${ROUTE}/export_transaction`;
export const GET_MERCHANT_KEY_PORTAL = `${ROUTE}/reepay`;

/**
 * * Dashboard
 */

export const DASHBOARD_ENDPOINT = "dashboard_report";
export const GET_DASHBOARD_REPORT = `${ROUTE}/${DASHBOARD_ENDPOINT}`;

/**
 * * Password
 */

export const ForgotPassword_ENDPOINT = "reset-password";
export const Forgot_Password = `${ROUTE}/${ForgotPassword_ENDPOINT}`;

/**
 * * Url Payment
 */

export const UrlPayment_ENDPOINT = "/signature/sacombank";
export const UrlPayment = `${UrlPayment_ENDPOINT}`;

/**
 * * Common
 */
export const ROUTE_STORAGE = "storage";
export const GET_DOWNLOAD_IMAGE = `${ROUTE_STORAGE}/download_image`;


/**
 * * Order
 */
export const ORDER_ENDPOINT = "checkout";
export const ORDER_LIST_ITEMS = `${ORDER_ENDPOINT}/order/merchant`;
export const GET_MERCHANT_URL = `${ORDER_ENDPOINT}/merchant`;

