import { createAsyncThunk } from "@reduxjs/toolkit";
import { TSService } from "../services/transaction.service";

export const fetchListTransaction = createAsyncThunk(
  "portal/fetchListTransaction",
  async (params, { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;
    const response = await TSService.getListTransaction({
      ...params,
      //domain
    });
    return response;
  }
);

export const fetchDashboardReport = createAsyncThunk(
  "portal/fetchDashboardReport",
  async (params, { dispatch, getState }) => {

    const state = getState();
    const { domain } = state.auth;

    const response = await TSService.getDashboardReport({
      ...params,
      //domain
    });

    return response.data;

  }
);

export const fetchDashboardReportFilter = createAsyncThunk(
  "portal/fetchDashboardReportFilter",
  async (params, { dispatch, getState }) => {
    const state = getState();
    const { domain } = state.auth;

    const response = await TSService.getDashboardReport({
      ...params,
      //domain
    });

    return response.data;

  }
);

export const exportOrderTransaction = createAsyncThunk(
  "portal/exportOrderTransaction",
  async (params, { dispatch, getState }) => {

    const state = getState();
    const { domain } = state.auth;

    return  await TSService.getExportURLTransaction({
      ...params,
      //domain
    });

  }
);


export const fetchDownloadImage = (dataReq) => async (dispatch, getState) => {
  let url = "";
  if (dataReq) {
    try {
      
      // const state = getState();
      // const { domain } = state.auth;

      await TSService.getDownloadImage(dataReq).then(
        (res) => {
          const { data } = res;
          if (data) {
            url = data?.image_url;
          }
        },
        (error) => {
          console.log("error1", error);
        }
      );
    } catch (error) {
      console.log("error2", error);
    }
    return url;
  }
};
